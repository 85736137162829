import Vue from 'vue';
import Router from 'vue-router';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import interceptorsSetup from '@/shared/helpers/interceptor';
import VueClipboard from 'vue-clipboard2';
import { RawLocation, Route, VueRouter } from 'vue-router/types/router';
import snackbarPlugin from '@/plugins/snackbar';
import { AppLogger } from '@/logger';
import { init, Replay, vueRouterInstrumentation } from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import Vuelidate from 'vuelidate';
import { Component } from 'vue-property-decorator';
import { isTestEnv } from './shared/functions';
import VueQuillEditor from 'vue-quill-editor';
// Styles
import 'material-design-icons-iconfont/dist/material-design-icons.css';
// import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';

declare global {
  // noinspection JSUnusedGlobalSymbolss
  export interface Window {
    basePath: string;
    Cypress: object;
    clarity: {
      (
        methodName: 'identify' | 'consent' | 'event' | 'upgrade' | 'set',
        userId?: string,
        sessionId?: string,
        pageId?: string,
        userName?: string,
      ): void;
    };
  }
}

Component.registerHooks(['validations']);
Vue.use(Vuelidate);

if (process.env.VUE_APP_SENTRY_DSN) {
  init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: vueRouterInstrumentation(router),
        tracePropagationTargets: ['localhost', 'next.id.cast-soft.com', /^\//],
      }),
      new Replay(),
    ],
  });
}
const logger = new AppLogger('Client root');

const consoleErrorOriginal = console.error;
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- skipcq: JS-0323
console.error = (...data: any[]): void => {
  consoleErrorOriginal(data);
  if (isTestEnv()) {
    AppLogger.showErrorOnPage(data.toString());
  }
  logger.error('Error from browser console', data, undefined, false);
};

interceptorsSetup(store, router);

VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

Vue.config.productionTip = false;

const originalPush = Router.prototype.push;
Router.prototype.push = function (location: RawLocation): Promise<Route> {
  return originalPush.call<VueRouter, [RawLocation], Promise<Route>>(this, location).catch(err => err);
};

Vue.use(snackbarPlugin, { store });

const options = {
  modules: {
    toolbar: [
      'bold',
      'italic',
      'underline',
      'strike',
      // 'blockquote',
      // 'code-block',
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
      { size: ['small', false, 'large', 'huge'] },
      { header: [1, 2, 3, 4, 5, 6, false] },
      { color: [] },
      { background: [] },
      { align: [] },
      'clean',
      'link',
    ],
  },
  theme: 'snow',
};
Vue.use(VueQuillEditor, options);

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
}).$mount('#app');
